<template>
  <div class="pageMain">
    <!-- 企业备案 -->
    <div class="content">
      <!-- 左边列表 -->
      <CustomTables
        :data="tableData"
        :titleIcon="true"
        :total="total"
        class="tableHear"
        :headerCellStyle="headerCellStyle"
        @handleCurrentChanges="handleCurrentChanges"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            :infoShow="true"
            info="项目信息"
            :iconShow="true"
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="" class="formItem">
                <el-input
                  v-model="searchForm.projName"
                  size="small"
                  placeholder="请输入项目名称"
                  @input="change"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchLeft>
        </template>
        <template slot="title">
          <div class="title">今日报警({{ 506 }})</div>
        </template>
        <el-table-column type="index" label="排序" width="50" align="center">
        </el-table-column>
        <el-table-column prop="projName" label="项目名称" align="center">
        </el-table-column>
      </CustomTables>
      <div class="tableBox">
        <!--右边列表  -->
        <div class="form">
          <div class="search-main">
            <el-row class="search-btn">
              <el-col :span="12">
                <div class="search-title">
                  <i class="el-icon-search"></i> 工程信息
                </div>
              </el-col>
            </el-row>
            <div class="condition-main">
              <el-descriptions >
                <el-descriptions-item label="工程名称"
                  >{{ infoData.projName }}</el-descriptions-item
                >
                <el-descriptions-item label="工程地址"
                  >{{ infoData.projName }}</el-descriptions-item
                >
                <el-descriptions-item label="建设单位"
                  >{{ infoData.projOwnerUnit }}</el-descriptions-item
                >
                
                <el-descriptions-item label="施工单位"
                  >{{ infoData.projConstUnit }}</el-descriptions-item
                >
                <el-descriptions-item label="监理单位"
                  >{{ infoData.projSuperUnit }}</el-descriptions-item
                >
              </el-descriptions>
              <!-- <el-form :inline="true" :model="searchForm">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="工程名称">{{ infoData.projName }} </el-form-item>
                </el-col>
               
                <el-col :span="6">
                  <el-form-item label="工程地址">{{ infoData.projAddress }} </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="建设单位">{{ infoData.projOwnerUnit }} </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="施工单位">{{ infoData.projConstUnit }} </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="监理单位">{{ infoData.projSuperUnit }} </el-form-item>
                </el-col>
              </el-row>
              </el-form> -->
            </div>
          </div>
          <CustomTables
            :data="tableData2"
            :headerCellStyle="headerCellStyle"
            @selectionChange="handleSelectionChange"
            @handleCurrentChange="handleCurrentChange2"
            @handleSizeChange="handleSizeChange2"
            :total="total2"
            title="工程列表文件"
          >
            <!-- <template slot="btns">
            <el-button type="success" size="small" @click="addFrom"
            icon="el-icon-plus"  class="btn">新建</el-button
            >
          </template> -->

            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column prop="annexTitle" label="文件名称" align="center">
            </el-table-column>
            <el-table-column prop="annexNote" label="文件说明" align="center">
            </el-table-column>
            <el-table-column prop="modifyUser" label="上传人" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="上传时间" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <span @click="detel(scope.row)" class="detail">删除</span>
              </template>
            </el-table-column>
          </CustomTables>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import data from "../../../api/modules/data";
export default {
  components: { CustomTables, SearchHead, SearchLeft },
  name: "companyFilings",
  data() {
    return {
      tableData: [],
      tableData2: [],
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      searchForm2: {
        currPage: 1,
        pageSize: 10,
      },
      options: [],
      total: 0,
      total2: 0,
      infoData: {},
    };
  },
  mounted() {
    this.getProjectListByPages();
  },
  methods: {
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getProjectListByPages();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.currPage = 1;
      this.getProjectListByPages();
    },
    handleCurrentChange2(pageIndex) {
      this.searchForm2.currPage = pageIndex;
      this.getDataAnnexListByPages(this.projId);
    },
    handleSizeChange2(pageSize) {
      this.searchForm2.pageSize = pageSize;
      this.searchForm2.currPage = 1;
      this.getDataAnnexListByPages(this.projId);
    },
    search() {
      console.log("searchForm", this.searchForm);
    },
    getProjectListByPages() {
      data.getProjectListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
        if (res.data.records.length > 0) {
          this.infoData = res.data.records[0];
          this.projId = res.data.records[0].projId;
          this.getDataAnnexListByPages(this.projId);
        }
      });
    },
    getDataAnnexListByPages(projId) {
      this.searchForm2.projId = projId;
      data.getDataAnnexListByPage(this.searchForm2).then((res) => {
        this.total2 = res.data.total;
        this.tableData2 = res.data.records;
      });
    },
    handleCurrentChanges(e) {
      this.infoData = e;
      this.projId = e.projId;
      this.getDataAnnexListByPages(e.projId);
    },
    change(e) {
      this.searchForm.projName = e;
      this.getProjectListByPages();
    },
    reset() {
      this.searchForm = {};
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    tableExport() {},
    handleSelectionChange() {},
    detel() {},
  },
};
</script>

<style scoped lang="less">
.content {
  display: flex;
  justify-content: space-between;
}
.tableBox {
  // margin-left: 10px;
  margin-left: 2%;
  width: 76%;
}
.tableHear {
  width: 22%;
  overflow: auto;
  // background:#e4ebf1
}
.title {
  margin-bottom: 10px;
  color: #000;
}

.formItem {
  margin-bottom: 0px;
}
.search-main {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-bottom: 20px;
  .search-btn {
    padding: 5px 20px;
  }
  .search-title {
    position: relative;
    top: 5px;
    font-weight: 600;
  }
}
.btn-main {
  text-align: right;
}
.condition-main {
  border-top: 1px solid #f0f0f0;
  padding: 15px 20px 0px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.collapsed {
  max-height: 50px; 
}
.on {
  font-size: 12px;
  margin-right: 15px;
}
.on:hover {
  cursor: pointer;
  color: #67c3ff;
}
.arrow {
  font-size: 16px;
  margin-right: 10px;
}
.arrow:hover {
  cursor: pointer;
  color: #67c3ff;
}
.btn {
  background-color: #ffffff;
  color: #008f4d;
}
.btn:hover {
  background-color: #008f4d;
}
.exportBtn {
  background-color: #008f4d;
}
</style>
