import api from '../httpServer'
import config from '../config'
// import store from '@/store/index'

const data ={
    //分页查询组织项目报表列表
    getProjReportListByPage(data) {
        return api.get(`${config.headerUrl}data/project/report/getProjReportListByPage`,data)
    },
     //获取项目选择列表
     GetProjectList(data) {
        return api.get(`${config.headerUrl}basicSet/GetProjectList`,data)
    },
    //分页查询项目设备报表列表
     getDeviceReportListByPage(data) {
        return api.get(`${config.headerUrl}data/device/report/getDeviceReportListByPage`,data)
    },
    //分页查询人员登录列表
    getLoginLogListByPage(data) {
        return api.get(`${config.headerUrl}data/user/login/getLoginLogListByPage`,data)
    },
    //人脸识别记录列表
    getFaceRecordListByPage(data) {
        return api.get(`${config.headerUrl}data/face/record/getFaceRecordListByPage`,data)
    },
    //分页查询项目信息列表
    getProjListByPage(data) {
        return api.get(`${config.headerUrl}data/device/alarm/getProjListByPage`,data)
    },
    //分页查询项目监控列表
    getRealProjectList(data) {
        return api.get(`${config.headerUrl}monitor/real/project/getRealProjectList`,data)
    },
     //分页查询告警明细列表
     getAlarmListByPage(data) {
        return api.get(`${config.headerUrl}data/device/alarm/getAlarmListByPage`,data)
    },
    //分页查询项目设备列表
    getRealProjectDeviceList(data) {
        return api.get(`${config.headerUrl}monitor/real/project/getRealProjectDeviceList`,data)
    },
    //分页查询项目信息列表
    getProjTotalListByPage(data) {
        return api.get(`${config.headerUrl}data/alarm/total/getProjListByPage`,data)
    },
    //分页查询告警明细统计列表
    getAlarmTotalListByPage(data) {
        return api.get(`${config.headerUrl}data/alarm/total/getAlarmTotalListByPage`,data)
    },
    //分页查询租赁单位评估列表
    getDataBusinessListByPage(data) {
        return api.get(`${config.headerUrl}data/business/getDataBusinessListByPage`,data)
    },
    //数据中心分页查询项目列表
    getProjectListByPage(data) {
        return api.get(`${config.headerUrl}data/annex/getProjectListByPage`,data)
    },
    //数据中心-分页查询工程列表文件列表
    getDataAnnexListByPage(data) {
        return api.get(`${config.headerUrl}data/annex/getDataAnnexListByPage`,data)
    },
    //分页查询项目下设备列表
    getDeviceCountByPage(data) {
        return api.get(`${config.headerUrl}data/face/record/getDeviceCountByPage`,data)
    },

}

export default data